import { getCampaign, getCreatorCodesWithDates } from './dates';

export const calculateCampaigns = ({ products, _prds, selectedCurrencyStore, campaign, showAllAppeals }) => {
  // if (!products || !Object.keys(products).length) return;
  console.log('on calculation=', products, _prds, selectedCurrencyStore, campaign, showAllAppeals);
  const campaignList = ['palestine', 'palestine-water-pipeline', 'gaza-camp', 'limbs-of-hope', 'north-lebanon-orphan', 'gaza-bakery'];
  const campaignData = {};
  campaignList.forEach(name => {
    const camp = getCampaign(name);
    campaignData[name] = {
      totalRaised: 0,
      totalSupporters: 0,
      creatorCodes: camp?.creatorCodes || [],
      goal: camp?.goal || 500000,
      products: [],
      givingProducts: [],
      mainProduct: camp?.mainProduct || null,
    };
  });

  let whereMostNeededProduct = null;
  let tempCurrentProduct = null;
  const tempOtherAppealProducts = [];
  const tempGivingProducts = [];

  const prdDataMap = new Map();
  _prds?.data?.forEach(_prdItem => prdDataMap.set(_prdItem.product_creator, _prdItem));
  // console.log('prdDataMap keys:', [...prdDataMap.keys()]);

  // 🔥 If products are missing or empty, build appealProducts only and return
  if (!products || !Object.keys(products).length) {
    let allCreators = getCreatorCodesWithDates();
    allCreators.forEach(product => {
      const salesData = prdDataMap.get(product.id);  // product.id = creator code
      if (salesData) {
        const campaignKey = product.campaign;
        // console.log("campaigns data=",campaignData)
        console.log("campaigns data=",campaignKey,campaignData[campaignKey])
        if (campaignKey && campaignData[campaignKey]) {
          campaignData[campaignKey].totalRaised += salesData.total_sale;
          campaignData[campaignKey].totalSupporters += salesData.total_supporters;
        }
      }
    });
    campaignList.forEach(campaignKey => {
      const camp = campaignData[campaignKey];
      if (camp.mainProduct?.showOnOtherAppeal) {
        tempOtherAppealProducts.push({
          id: camp.mainProduct.product_id,
          name: camp.mainProduct.name,
          description: camp.mainProduct.product_description,
          image_link: camp.mainProduct.product_image_link,
          totalRaised: camp.totalRaised,
          totalSupporters: camp.totalSupporters,
          campaign: campaignKey,
          goal: camp.goal,
          link: camp.mainProduct.link,
          creator: camp.mainProduct.creator,
        });
      }
    });
    // ✅ Return appealProducts only
    return {
      givingProducts: [],
      currentGivingProducts: [],
      appealProducts: tempOtherAppealProducts,
      currentProduct: null,
    };
  }

  const allProducts = products.flatMap(cat =>
    cat.sub_categories.flatMap(sub =>
      sub.products.map(product => ({
        ...product,
        total_count: 0,
        total_sale: 0,
        goal: 500000,
        campaign: '',
      })),
    ),
  );

  allProducts.forEach(product => {
    if (product.creator === 'MKD-MN-001') whereMostNeededProduct = product;
    campaignList.forEach(campaignKey => {
      const camp = campaignData[campaignKey];
      const mainProduct = camp.mainProduct;
      // const isMatch = camp.creatorCodes.some(code => product.creator === code || product.creator.includes(code) || code.includes(product.creator));
      const isMatch = camp.creatorCodes.some(code => product.creator === code);
      if (isMatch) {
        product.campaign = campaignKey;
        product.goal = camp.goal;
        if (product.creator === mainProduct.creator) {
          product.name = mainProduct.name;
          product.short_description = mainProduct.product_description;
          product.image_link = mainProduct.product_image_link;
          product.link = mainProduct.link;
        }
        camp.products.push(product);
      }
    });

    const salesData = prdDataMap.get(product.creator);
    if (salesData) {
      product.total_count = salesData.total_count;
      product.total_sale = salesData.total_sale;
      const campaignKey = product.campaign;
      if (campaignKey) {
        campaignData[campaignKey].totalRaised += salesData.total_sale;
        campaignData[campaignKey].totalSupporters += salesData.total_supporters;
      }
    }
  });

  const determineCurrentProduct = campaignName => {
    if (!campaignName) return whereMostNeededProduct; // Safeguard
    const camp = campaignData[campaignName];
    if (camp && camp.products.length > 0) {
      let product = null;
      if (camp.mainProduct?.creator) {
        product = camp.products.find(p => p.creator === camp.mainProduct.creator);
      }
      if (!product) product = camp.products[0];
      product = { ...product };
      product.totalRaised = camp.totalRaised;
      product.totalSupporters = camp.totalSupporters;
      return product;
    }
    return whereMostNeededProduct;
  };

  tempCurrentProduct = campaign ? determineCurrentProduct(campaign) : whereMostNeededProduct;

  campaignList.forEach(campaignKey => {
    const camp = campaignData[campaignKey];
    camp.products.forEach(product => {
      if (product['aud'] > 1) {
        camp.givingProducts.push(product);
        tempGivingProducts.push(product);
      }
    });
  });

  // Build Other Appeals
  campaignList.forEach(campaignKey => {
    const camp = campaignData[campaignKey];
    if (camp.products.length > 0 && (showAllAppeals || campaignKey !== tempCurrentProduct?.campaign)) {
      let mainProduct = null;
      if (camp.mainProduct?.creator) {
        mainProduct = camp.products.find(p => p.creator === camp.mainProduct.creator);
      }
      if (!mainProduct) mainProduct = camp.products[0];
      if (camp.mainProduct.showOnOtherAppeal) {
        tempOtherAppealProducts.push({
          id: mainProduct.id,
          name: mainProduct.name,
          description: mainProduct.short_description,
          image_link: mainProduct.image_link,
          totalRaised: camp.totalRaised,
          totalSupporters: camp.totalSupporters,
          campaign: campaignKey,
          goal: camp.goal,
          link: camp.mainProduct.link,
          creator: mainProduct.creator,
        });
      }
    }
  });

  const sortedGiving = tempGivingProducts
    .filter(Boolean)
    .sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()]);

  // Return all needed results instead of setting state here
  return {
    givingProducts: sortedGiving,
    currentGivingProducts: sortedGiving,
    appealProducts: tempOtherAppealProducts,
    currentProduct: tempCurrentProduct,
  };
};
