import React, {useState, useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectSelectedCountry} from './store/user';
import {builder} from '@builder.io/react';
import RouteContext from './context/route';
import NotFound from './pages/other/notFound';
import CheckoutPage from './pages/checkout';
import SuccessPage from './pages/other/success';
import ErrorPage from './pages/other/error';
import ReceiptPage from './pages/receipt/receipt';
import AllAppeals from './pages/other/allAppeals';
import Products from './pages/products/products';
import AliBanatPage from './pages/other/aliBanat';
import FAQsPage from './pages/other/faq';
import PoliciesPage from './pages/other/policies';
import SocialMedia from './pages/other/socialMedia';
import GDPR from './pages/other/gdpr';
import PrivacyPolicyPage from './pages/other/privacyPolicy';
import AnnualReports from './pages/other/annualReports';
import ScrollToTop from './utils/ScrollToTop';
import Contact from './pages/contact';
import Live from './pages/other/Live';
import AllProducts from './pages/allProducts';
import AppPage from './pages/other/app';
import PurposePage from './pages/other/purpose';
import CancelPage from './pages/other/cancel';
import GetReceiptPage from './pages/receipt/getReceipt';
import OptOutPage from './pages/other/opt-out';
import ParticipantPage from './pages/other/participantRegistration';
import QurbanMostPopularPage from './pages/qurban/qurban-mostPopular';
import SadaqahMostPopularPage from './pages/qurban/sadaqah-mostPopular';
import SheepsPage from './pages/qurban/sheeps';
import CowsPage from './pages/qurban/cows';
import ZakatLandingPage from './pages/zakat/zakat-landing';
import DashboardPage from './pages/dashboard';
import QuickDonatePage from './pages/other/quick-donate';
import FoodAndWaterLandingPage from './pages/foodAndWater/index';
import LoginPage from './pages/dashboard/pages/login';
import OneBillionLegacyPage from './pages/one-billion';
import MorningTeaPage from './pages/events/morning-tea';
import LiveFundraiserPage from './pages/events/live-fundraiser';
import YasminMogahedIndonesiaPage from './pages/events/yasmin-indonesia';
import YasminMogahedMalaysiaPage from './pages/events/yasmin-malaysia';
import YasminMogahedSingaporePage from './pages/events/yasmin-singapore';
import AsmaaAndBelalPage from './pages/events/asmaa-and-belal';
import VIPPage from './pages/vip';
import NewVolunteerPage from './pages/volunteer';
import OrphanEducationLandingPage from './pages/orphans/orphan-education';
import OrphanSupportLandingPage from './pages/orphans/orphan-support';
import MoroccoEmergencyLandingPage from './pages/crisis-and-emergencies/morocco-emergency';
import EarthquakeAppealPage from './pages/crisis-and-emergencies/earthquake-appeal';
import ZakatWidgetEmbedded from './pages/zakat/zakatWidgetEmbedded';
import OrphanHomesLandingPage from './pages/sadaqah-jariyah/orphan-homes';
import YasirandnoumanPage from './pages/events/yasir';
import SustainableLandingPage from './pages/sadaqah-jariyah/sustainable';
import OrphanagesLandingPage from './pages/sadaqah-jariyah/orphanages';
import EmergencyLandingPage from './pages/sadaqah-jariyah/emergency';
import YasirandnoumanMalaysiaPage from './pages/events/yasir-malaysia';
import NoumanalikhanLandingPage from './pages/events/noumanalikhan';
import MakeADonationLandingPage from './pages/donate-now/make-a-donation';
import PalestineCampaignLandingPage from './pages/crisis-and-emergencies/palestine-campaign';
import NoumanalikhanMalaysiaLandingPage from './pages/events/noumanalikhan-malaysia';
import LoveOnesLandingPage from './pages/loved-ones-legacy';
import UkModal from './components/general/UkModal';
import WinterLandingPage from './pages/winter';
import SpecialCasesLandingPage from './pages/special-cases';
import CallCenter from './pages/call-center';
import PalestineResponseLandingPage from './pages/crisis-and-emergencies/palestine-response';
import EmergencyResponseLandingPage from './pages/crisis-and-emergencies/emergency-response';
import MuftiMenkLandingPage from './pages/events/muftimenk';
import DonorReceipts from './pages/receipt/donorReceipts';
import USAPage from './pages/ukHome/usa-home';
import EventDetails from './pages/events/eventDetails';
import YasminmogahedLandingPage from './pages/events/yasminmogahed';
import GlobalPage from './pages/global';
import USAGeneralDonation from './pages/usa/usaGeneralDonation';
import SubscriptionLandingPage from './pages/subscription/index';
import PalestineArabic from './pages/crisis-and-emergencies/palestineArabic';
import DetailsProduct from './pages/products/details';
import FidyaKaffarah from './pages/fidya-kaffarah/fidyaKaffarah';
import PalestineWeekly from './pages/crisis-and-emergencies/palestine-weekly';
import YasminmogahedAustraliaPage from './pages/events/yasminmogahed-australia';
import UpTimeStatus from './pages/other/upTimeStatus';
import ZakatAlFitr from './pages/zakat/zakatAlFitr';
import MasjidsNewLandingPage from './pages/sadaqah-jariyah/masjidsNew';
import ProjectNoorUAT from './pages/sadaqah-jariyah/projectNoorUAT';
import FidyaKaffarahUat from './pages/fidya-kaffarah/fidyaKaffarahUat';
import FoodAidUATLandingPage from './pages/foodAndWater/food-aid-UAT';
import FundraisingLandingPageUAT from './pages/fundraising/index-uat';
import HealthLandingPage from './pages/sadaqah-jariyah/health';
import OurPromise from './pages/other/ourPromise';
import ZakatCalculatorUATLandingPage from './pages/zakat/zakatCalculatorUAT';
import SadaqahjariahUat from './pages/sadaqah-jariyah/sadaqahJariahUat';
import OrphansUat from './pages/orphans/orphansUat';
import BuildWaterWellUat from './pages/sadaqah-jariyah/buildWaterWellUat';
import OrphansFeastUat from './pages/orphans/orphansFeastUat';
import WaterAidUATLandingPage from './pages/foodAndWater/water-aid-uat';
import VolunteerUATPage from './pages/volunteer/volunteer-uat';
import ZakatUat from './pages/zakat/zakatUat';
import KaffarahLandingPage from './pages/fidya-kaffarah/kaffarah';
import FidyaLandingPage from './pages/fidya-kaffarah/fidya';
import HundredDonationsPolicy from './pages/other/hundredDonationsPolicy';
import Fitrana from './pages/fitrana';
import SentCryptoPage from './pages/crypto/sentCrypto';
import CancelCryptoPage from './pages/crypto/cancelCrypto';
import DonateToPalestine from './pages/crisis-and-emergencies/donateToPalestine';
import RamadanDua from './pages/ramadan/ramadanDua';
import ZakatOnGold from './pages/zakat/zakatOnGold';
import DuaForBreakingFast from './pages/ramadan/duaForBreakingFast';
import SponsorAnOrphan from './pages/orphans/sponsorAnOrphan';
import WhenIsEid from './pages/ramadan/whenIsEid';
import Aqiqah from './pages/sadaqah-jariyah/aqiqah';
import OliveTreeSadaqah from './pages/sadaqah-jariyah/ oliveTreeSadaqah';
import SadaqahjariahForDeceased from './pages/sadaqah-jariyah/SadaqahjariahForDeceased';
import GiftGiving from './pages/giftGiving';
import SadaqahAndLillah from './pages/sadaqah-jariyah/sadaqahAndLillah';
import DuaForPalestine from './pages/crisis-and-emergencies/duaForPalestine';
import DuaForParents from './pages/sadaqah-jariyah/duaForParents';
import NewsPage from './pages/newsPage';
import Nisab from './pages/zakat/nisab';
import CampaignTiktok from './pages/other/campaignTiktok';
import RamadanLandingPage from './pages/ramadan';
import HomePageUAT from './pages/home/index-UAT';
import HomePageUAT2024Nov from './pages/home/index-UAT-2024-Nov';
import PalestineFamineLandingPage from './pages/crisis-and-emergencies/palestineFamine';
import GiftOfSoundLandingPage from './pages/sadaqah-jariyah/giftOfSound';
import PalestineLandingPage from './pages/crisis-and-emergencies/palestine';
import LaylaTulQadrLandingPage from './pages/ramadan/laylatulQadar';
import PhishingLandingPage from './pages/other/phishing';
import LaylatulQadr2024LandingPage from './pages/ramadan/laylatulQadr2024';
import AdminFeesPage from './pages/other/adminFees';
import SubscriptionABLandingPage from './pages/subscription-ab';
import LiveMeals from './pages/other/live-meals';
import PalestineRefugeeLandingPage from './pages/crisis-and-emergencies/palestineRefugeeSettlement';
import EidAlFitr2024 from './pages/eidAlFitr2024';
import EidMubarak2024 from './pages/eidAlFitr2024/eidMubarak2024';
import SaveOrphansLandingPage from './pages/crisis-and-emergencies/save-orphans';
import ChildrenInGazaLandingPage from './pages/crisis-and-emergencies/childrenInGaza';
import TopAppeals from './pages/topAppeals';
import DonorLogin from './pages/donor/pages/login';
import DonorPage from './pages/donor';
import VerifyOTP from './pages/donor/pages/verifyOTP';
import BelalAssadApril from './pages/events/belalassad-april';
import Sadaqah from './pages/sadaqah-jariyah/sadaqah';
import QurbaniLandingPage from './pages/qurban/qurbani';
import QurbanMainLandingPage from './pages/qurban/qurban-main';
import Qurbani2024LandingPage from './pages/qurban/qurbani-2024';
import DonateToGaza from './pages/crisis-and-emergencies/donateToGaza';
import DetailsPalestineProduct from './pages/products/detailsPalestine';
import DhulHijjah2024LandingPage from './pages/qurban/dhul-hijjah-2024';
import EidAlAdha2024LandingPage from './pages/qurban/eid-al-adha-2024';
import KurbanBayramiLandingPage from './pages/qurban/kurban-bayrami-2024';
import AfghanistanLandingPageUAT from './pages/crisis-and-emergencies/afghanistan-uat';
import CheapestQurbani2024LandingPage from './pages/qurban/cheapest-qurbani-2024';
import WidgetEmbedded from './pages/other/embedWidget/widgetEmbedded';
import PalestineAiaPartnershipLandingPage from './pages/crisis-and-emergencies/palestine-aia-partnership';
import MajedMahmoudLandingPage from './pages/events/majedMahmoud';
import QurbanPalestineLandingPage from './pages/qurban/qurban-palestine';
import PalestineArabicLandingPage from './pages/crisis-and-emergencies/palestine-ar';
import UltimateQurbanLandingPage from './pages/qurban/qurban-ultimate';
import OrphansAiaSponsorshipLandingPage from './pages/crisis-and-emergencies/orphans-aia-sponsorshop';
import QurbanDhulHijjahLandingPage from './pages/qurban/qurban-dhul-hijjah';
import QurbanDayOfArafatLandingPage from './pages/qurban/qurban-day-of-arafat';
import DoubleTheDonationPage from './pages/double-the-donation';
import QurbaniDonationLandingPage from './pages/qurban/qurbani-donation';
import PalastineMedicalRelief from './pages/medicalforPalestine/palestine-medical';
import Top10Products from './pages/other/top10Products';
import QurbanEidAlAdhaLandingPage from './pages/qurban/qurban-eid-al-adha';
import AppealLandingPage from './pages/appeal';
import AliBanatUSALandingPage from './pages/events/aliBanatUsa';
import AliBanatCADLandingPage from './pages/events/aliBanatCad';
import MuharramLandingPage from './pages/muharram';
import MATWAppealDetailsLandingPage from './pages/MATWAppeal/details';
import MATWAppealLandingPage from './pages/MATWAppeal';
import EventLandingPage from './pages/events/eventPage';
import ChildrenOfGaza from './pages/childrenOfGaza';
import BangladeshAppeal from './pages/crisis-and-emergencies/bangladesh-appeal';
import CountriesInCrisisLandingPage from './pages/crisis-and-emergencies/countriesInCrisis';
import LimbsOfHopeLandingPage from './pages/other/limbsOfHope';
import GazaAppealLandingPage from './pages/crisis-and-emergencies/gaza-appeal';
import LebanonAppealLandingPage from './pages/crisis-and-emergencies/lebanon-appeal';
import PalestineImpact from './pages/crisis-and-emergencies/palestine-impact';
import MatwAppeals from './pages/other/matw-appeals';
import PalestineEmergencyAppealLandingPage from './pages/crisis-and-emergencies/palestine-emergency-appeal';
import HomePageUATNew from './pages/home/index-UAT-New';
import CampaignTracker from './components/general/companyTracker';
import HomePageUATUpdated from './pages/home/index-UAT-Home';
import WidgetsUAT from './pages/home/index-UAT-Widgets';
import MATWAppealDetailsLandingPageUATx from './pages/MATWAppeal/details-uat';
import DonationsForPalestine from './pages/home/donations-for-palestine';
import NamazTimes from "./pages/namaz-times/NamazTimes";
import TestWidgetPage from './pages/other/test-widgets';
import QiblaFinder from "./pages/qibla-finder/QiblaFinder";
import {SehrIftarTime} from "./pages/namaz-times/SehrIftarTime";
import RamadanDailyDonation from './pages/ramadan/ramadanDaily';
import HomePageUSA from './pages/home/homepage-usa';
import HomePageNew from './pages/home/index-new';
import LastTenNightsDailyGiving from './pages/ramadan/LastTenNightsDailyGiving';
import LastTenNightsDailyGivingNew from './pages/ramadan/LastTenNightsDailyGivingNew';
import TikTokLandingPage from './pages/social/TikTokLandingPage';
import CheckoutPageAia from './pages/checkout_Aia';
import NewDonorLogin from "./components/userdashboard/NewDonorLogin";

let startTime = null;

export default function AllRoutes() {
    const [routeState, setRouteState] = useState([{name: 'MATW Home', id: '/'}]);
    const [showModal, setShowModal] = useState(false);
    const [cmsRoutes, setCmsRoutes] = useState([]);
    const [loadingPaths, setLoadingPaths] = useState(true);
    const isAlreadyShown = JSON.parse(localStorage.getItem('isModalShown')) || false;
    const country = useSelector(selectSelectedCountry);
    const location = useLocation();
    const prefixes = ['', '/fr', '/ar', '/es'];

    useEffect(() => {
        const storedCmsRoutes = localStorage.getItem('storedCmsRoutes');
        const storedTimestamp = localStorage.getItem('storedTimestamp');
        if (storedCmsRoutes && storedTimestamp && Date.now() - parseInt(storedTimestamp, 10) < 3600000) {
            setCmsRoutes(JSON.parse(storedCmsRoutes));
            setLoadingPaths(false);
        } else {
            fetchCmsRoutes();
        }
    }, []);

    async function fetchCmsRoutes() {
        try {
            const response = await fetch('https://matwproject.org/backend/index.php/api/routes?route_type=builder');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            localStorage.setItem('storedCmsRoutes', JSON.stringify(data));
            localStorage.setItem('storedTimestamp', Date.now().toString());
            setCmsRoutes(routes);
        } catch (error) {
            console.error('Failed to fetch builder routes:', error);
        } finally {
            setLoadingPaths(false);
        }
    }

    const closeModal = () => {
        setShowModal(false);
        localStorage.setItem('isModalShown', 'true');
    };

    const checkDayAndShowDialog = () => {
        if (window.location.hostname === 'matwproject.org.uk') {
            return true;
        } else {
            return false;
        }
    };

    const trackScroll = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        if (dayOfWeek !== 5) localStorage.setItem('isModalShown', 'false');
        if (dayOfWeek === 5 && checkDayAndShowDialog() && country === 'uk') {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            const mints = new Date(elapsedTime).getMinutes();
            const secs = new Date(elapsedTime).getSeconds();
            if (secs > 20 || mints > 0) {
                setShowModal(true);
                window.removeEventListener('scroll', trackScroll);
            }
        } else {
            window.removeEventListener('scroll', trackScroll);
        }
    };

    useEffect(() => {
        startTime = Date.now();
        checkDayAndShowDialog(); //only for Uk domain
        window.addEventListener('scroll', trackScroll);
        return () => {
            window.removeEventListener('scroll', trackScroll);
        };
    }, [country]);

    const getHomePage = () => {
        let component = '';
        // let pathNames = window.location.pathname.split('/');
        // console.log("on get home page=",pathNames,window.location.pathname)
        // if(pathNames[1] === '' || pathNames[1] === 'fr' || pathNames[1] === 'ar' || pathNames[1] === 'es') {
        //   component = <HomePageUAT />;
        // } else {
        //   component = <SubCategory />;
        // }
        switch (window.location.hostname) {
            case 'matwproject.org.uk':
                // component = <UKHome />;
                component = <HomePageUAT/>;
                break;
            case 'matwprojectusa.org':
                // component = <USAPage country={"usa"}/>;
                component = <HomePageUAT/>;
                break;
            case 'localhost':
                // component = <UKHome />;
                // component =<USAPage country={"usa"}/>;
                component = <HomePageUAT/>;
                break;
            case 'us.matwproject.org':
                component = <HomePageUSA/>;
                break;
            default:
                component = <HomePageUAT/>;
                break;
        }
        return component;
    };

    const routes = [
        {path: '/', element: getHomePage()},
        {path: '/home-new', element: <HomePageUATUpdated/>},
        {path: '/indexNew', element: <HomePageUAT2024Nov/>},
        {path: '/donate-to-palestine-now', element: <HomePageUATNew/>},
        {path: '/donations-for-palestine', element: <DonationsForPalestine/>},
        {path: '/donor', element: <NewDonorLogin/>},
        {path: '/login', element: <LoginPage/>},
        {path: '/donor/login', element: <DonorLogin/>},
        {path: '/donor/verify-otp', element: <VerifyOTP/>},
        {path: '/usa-general-donation', element: <USAGeneralDonation/>},
        {path: '/checkout', element: <CheckoutPage/>},
        {path: '/checkout-aia', element: <CheckoutPageAia/>},
        {path: '/all-products', element: <AllProducts/>},
        {path: '/all-appeals', element: <AllAppeals/>},
        {path: '/participant-registration', element: <ParticipantPage/>},
        {path: '/fundraising', element: <FundraisingLandingPageUAT/>},
        {path: '/qurban/sheep', element: <SheepsPage/>},
        {path: '/qurban/cow', element: <CowsPage/>},
        {path: '/qurban-most-popular', element: <QurbanMostPopularPage/>},
        {path: '/sadaqah-most-popular', element: <SadaqahMostPopularPage/>},
        {path: '/zakat-calculator', element: <ZakatCalculatorUATLandingPage/>},
        {path: '/events/:any', element: <AppealLandingPage/>, exact: true},
        {path: '/nisab', element: <Nisab/>},
        {path: '/zakat-landing', element: <ZakatLandingPage/>},
        {path: '/zakat', element: <ZakatUat/>},
        {path: '/zakat-on-gold', element: <ZakatOnGold/>},
        {path: '/zakat/zakat-al-fitr', element: <ZakatAlFitr/>},
        {path: '/success', element: <SuccessPage/>},
        {path: '/ali-banat', element: <AliBanatPage/>},
        {path: '/purpose', element: <PurposePage/>},
        {path: '/gdpr', element: <GDPR/>},
        {path: '/faq', element: <FAQsPage/>},
        {path: '/opt-out', element: <OptOutPage/>},
        {path: '/cancel', element: <CancelPage/>},
        {path: '/get-receipt', element: <GetReceiptPage/>},
        {path: '/social-media', element: <SocialMedia/>},
        {path: '/annual-reports', element: <AnnualReports/>},
        {path: '/policies', element: <PoliciesPage/>},
        {path: '/privacypolicy', element: <PrivacyPolicyPage/>},
        {path: '/volunteer', element: <NewVolunteerPage/>},
        {path: '/receipt', element: <ReceiptPage/>},
        {path: '/app', element: <AppPage/>},
        {path: '/global', element: <GlobalPage/>},
        {path: '/quick-donate', element: <QuickDonatePage/>},
        {path: '/food-and-water-aid', element: <FoodAndWaterLandingPage/>},
        {path: '/food-and-water-aid/water-aid', element: <WaterAidUATLandingPage/>},
        {path: '/food-and-water-aid/food-aid', element: <FoodAidUATLandingPage/>},
        {path: '/the-1-billion-legacy', element: <OneBillionLegacyPage/>},
        {path: '/vip', element: <VIPPage/>},
        {path: '/call-center', element: <CallCenter/>},
        {path: '/contact', element: <Contact/>},
        {path: '/error', element: <ErrorPage/>},
        {path: '/donate-to-palestine', element: <DonateToPalestine/>, exact: true},
        {path: '/food-and-water-aid-fr', element: <FoodAndWaterLandingPage lang={'fr'}/>},
        {path: '/food-and-water-aid-ar', element: <FoodAndWaterLandingPage lang={'ar'}/>},
        {path: '/donate-to-gaza', element: <DonateToGaza/>, exact: true},
        {path: '/donate-now/make-a-donation', element: <MakeADonationLandingPage/>},
        {path: '/loved-ones-legacy', element: <LoveOnesLandingPage/>},
        {path: '/winter', element: <WinterLandingPage/>},
        {path: '/live', element: <Live/>},
        {path: '/donor-receipts', element: <DonorReceipts/>},
        {path: '/subscription', element: <SubscriptionLandingPage/>},
        {path: '/subscription-regular-giving', element: <SubscriptionABLandingPage/>},
        {path: '/ramadan', element: <RamadanLandingPage/>},
        {path: '/ramadan/laylatul-qadr', element: <LaylaTulQadrLandingPage/>},
        {path: '/orphans-fr', element: <OrphansUat lang={'fr'}/>},
        {path: '/orphans-ar', element: <OrphansUat lang={'ar'}/>},
        {path: '/palestine-campaign-3-ar', element: <PalestineArabic/>},
        {path: '/ramadan/fidya-kaffarah', element: <FidyaKaffarah/>},
        {path: '/kaffarah', element: <KaffarahLandingPage/>},
        {path: '/fidya', element: <FidyaLandingPage/>},
        {path: '/uptime-status', element: <UpTimeStatus/>},
        {path: '/singapore', element: <USAPage country={'singapore'}/>},
        {path: '/our-promise', element: <OurPromise/>},
        {path: '/processing-crypto', element: <SentCryptoPage/>},
        {path: '/cancel-crypto', element: <CancelCryptoPage/>},
        {path: '/aqiqah', element: <Aqiqah/>},
        {path: '/100-donation-policy', element: <HundredDonationsPolicy/>},
        {path: '/dua-for-breaking-fast', element: <DuaForBreakingFast/>},
        {path: '/difference-between-sadaqah-and-lillah', element: <SadaqahAndLillah/>},
        {path: '/dua-for-palestine', element: <DuaForPalestine/>},
        {path: '/dua-for-parents', element: <DuaForParents/>},
        {path: '/when-is-eid-2024', element: <WhenIsEid/>},
        {path: '/ramadan-dua', element: <RamadanDua/>},
        {path: '/gift-giving', element: <GiftGiving/>},
        {path: '/fitrana', element: <Fitrana/>},
        {path: '/news', element: <NewsPage/>},
        {path: '/muharram', element: <MuharramLandingPage type={'main'}/>},
        {path: '/muharram-2024', element: <MuharramLandingPage type={'duplicated'}/>},
        {path: '/events/live-fundraiser', element: <LiveFundraiserPage/>},
        {path: '/events/morningtea', element: <MorningTeaPage/>},
        {path: '/events/yasminmogahed', element: <YasminmogahedLandingPage/>, exact: true},
        {path: '/events/yasminmogahed-australia-feb', element: <YasminmogahedAustraliaPage/>, exact: true},
        {path: '/events/qasimrafique-australia-feb', element: <YasminmogahedAustraliaPage/>, exact: true},
        {path: '/events/alihammuda-au-tour', element: <EventLandingPage type="alihamud"/>, exact: true},
        {path: '/events/iftardinner-march', element: <EventLandingPage type="iftar"/>, exact: true},
        {path: '/events/muftimenek-malaysia', element: <EventLandingPage type="muftimenek"/>, exact: true},
        {path: '/events/belal-assad-europe', element: <EventLandingPage type="belalasad"/>, exact: true},
        {path: '/events/belal-assad-canada', element: <EventLandingPage type="belalasad"/>, exact: true},
        {path: '/events/belal-assad-uk', element: <EventLandingPage type="belalasad"/>, exact: true},
        {path: '/events/belal-assaad-south-africa', element: <EventLandingPage type="belal"/>, exact: true},
        {path: '/events/shaun-king-malaysia', element: <EventLandingPage type="shaun"/>, exact: true},
        {path: '/events/yasmin-mogahed-malaysia-november', element: <EventLandingPage type="yasmin"/>},
        {path: '/events/doctor-rania-malaysia-november', element: <EventLandingPage type="rania"/>},
        {path: '/events/mustafa-briggs-november', element: <EventLandingPage type="mustafa"/>},
        {path: '/events/muslim-family-fest-november', element: <EventLandingPage type="muslim"/>},
        {path: '/events/youssra-kandil-november', element: <EventLandingPage type="youssra"/>},
        {path: '/events/nauman-ali-khan-december', element: <EventLandingPage type="nauman"/>},
        {path: '/events/abdul-wahab', element: <EventLandingPage type="abdulwahab"/>, exact: true},
        {path: '/events/majedmahmoud-qurban', element: <MajedMahmoudLandingPage/>, exact: true},
        {path: '/events/yasminmogahedindonesia', element: <YasminMogahedIndonesiaPage/>, exact: true},
        {path: '/events/yasminmogahedmalaysia', element: <YasminMogahedMalaysiaPage/>, exact: true},
        {path: '/events/yasminmogahedsingapore', element: <YasminMogahedSingaporePage/>, exact: true},
        {path: '/events/asmaaandbelal', element: <AsmaaAndBelalPage/>, exact: true},
        {path: '/events/yasirandnouman', element: <YasirandnoumanPage/>, exact: true},
        {path: '/events/yasirandnoumanmalaysia', element: <YasirandnoumanMalaysiaPage/>, exact: true},
        {path: '/events/noumanalikhan', element: <NoumanalikhanLandingPage/>, exact: true},
        {path: '/events/muftimenk', element: <MuftiMenkLandingPage/>, exact: true},
        {path: '/events/noumanalikhan/malaysia', element: <NoumanalikhanMalaysiaLandingPage/>, exact: true},
        {path: '/events/belalassad-april2024', element: <BelalAssadApril/>, exact: true},
        {path: '/events/alibanatlegacy', element: <AliBanatUSALandingPage/>, exact: true},
        {path: '/events/alibanatlegacyca', element: <AliBanatCADLandingPage/>, exact: true},
        {path: '/sadaqah-jariyah', element: <SadaqahjariahUat/>},
        {path: '/sadaqah', element: <Sadaqah/>},
        {path: '/sadaqah-jariyah-for-deceased', element: <SadaqahjariahForDeceased/>},
        {path: '/sadaqah-jariyah/project-noor', element: <ProjectNoorUAT/>},
        {path: '/sadaqah-jariyah/orphan-homes', element: <OrphanHomesLandingPage/>},
        {path: '/sadaqah-jariyah/build-a-water-well', element: <BuildWaterWellUat/>},
        {path: '/olive-tree-sadaqah', element: <OliveTreeSadaqah/>},
        {path: '/sadaqah-jariyah/sustainable', element: <SustainableLandingPage/>},
        {path: '/sadaqah-jariyah/orphans', element: <OrphanagesLandingPage/>},
        {path: '/sadaqah-jariyah/build-a-masjid', element: <MasjidsNewLandingPage type="buildAMasjid"/>},
        {path: '/donation-for-masjid', element: <MasjidsNewLandingPage type="donationForMasjid"/>},
        {path: '/sadaqah-jariyah/emergency', element: <EmergencyLandingPage/>},
        {path: '/sadaqah-jariyah/health', element: <HealthLandingPage/>},
        {path: '/orphans', element: <OrphansUat lang='en'/>},
        {path: '/fr/orphans', element: <OrphansUat lang='fr'/>},
        {path: '/orphans-fr', element: <OrphansUat lang={'fr'}/>},
        {path: '/orphans-ar', element: <OrphansUat lang={'ar'}/>},
        {path: '/orphans/orphan-feast', element: <OrphansFeastUat/>},
        {path: '/orphans/education', element: <OrphanEducationLandingPage/>},
        {path: '/orphans/support-an-orphan', element: <OrphanSupportLandingPage/>},
        {path: '/sponsor-an-orphan', element: <SponsorAnOrphan/>},
        {path: '/orphans/orphan-widget', element: <ZakatWidgetEmbedded/>},
        {path: '/crisis-and-emergencies/palestine', element: <PalestineLandingPage/>, exact: true},
        {path: '/palestine-emergency-appeal', element: <PalestineEmergencyAppealLandingPage/>, exact: true},
        {
            path: '/crisis-and-emergencies/palestine-famine',
            element: <PalestineFamineLandingPage lang={'en'}/>,
            exact: true
        },
        {path: '/palestine-famine-ar', element: <PalestineFamineLandingPage lang={'ar'}/>, exact: true},
        {path: '/palestine-famine-fr', element: <PalestineFamineLandingPage lang={'fr'}/>, exact: true},
        {path: '/crisis-and-emergencies/palestine-subscription', element: <SubscriptionLandingPage/>, exact: true},
        {path: '/crisis-and-emergencies/palestine-campaign', element: <PalestineCampaignLandingPage/>, exact: true},
        {path: '/crisis-and-emergencies/palestine-response', element: <PalestineResponseLandingPage/>, exact: true},
        {path: '/crisis-and-emergencies/emergency-response', element: <EmergencyResponseLandingPage/>, exact: true},
        {path: '/crisis-and-emergencies/morocco', element: <MoroccoEmergencyLandingPage/>, exact: true},
        {
            path: '/crisis-and-emergencies/libya-floods',
            element: <Navigate to="/crisis-and-emergencies" replace/>,
            exact: true
        },
        {path: '/crisis-and-emergencies/earthquake-appeal', element: <EarthquakeAppealPage/>, exact: true},
        {path: '/crisis-and-emergencies/palestine-weekly-au', element: <PalestineWeekly country={'au'}/>, exact: true},
        {
            path: '/crisis-and-emergencies/palestine-weekly-usa',
            element: <PalestineWeekly country={'usa'}/>,
            exact: true
        },
        {path: '/crisis-and-emergencies/palestine-weekly-uk', element: <PalestineWeekly country={'uk'}/>, exact: true},
        {
            path: '/crisis-and-emergencies/palestine-weekly-global',
            element: <PalestineWeekly country={'global'}/>,
            exact: true
        },
        {path: '/crisis-and-emergencies/save-orphans', element: <SaveOrphansLandingPage lang={'en'}/>, exact: true},
        {
            path: '/crisis-and-emergencies/palestine-refugee-settlement',
            element: <PalestineRefugeeLandingPage lang={'en'} type="RefusseP"/>
        },
        {path: '/crisis-and-emergencies/bangladesh-appeal', element: <BangladeshAppeal/>},
        {path: '/crisis-and-emergencies/countries-in-crisis', element: <CountriesInCrisisLandingPage/>},
        {path: '/medical-aid-for-palestinians', element: <PalastineMedicalRelief/>},
        {path: '/palestinian-refugees-egypt', element: <PalestineRefugeeLandingPage lang={'en'}/>},
        {path: '/palestine-refugee-settlement-ar', element: <PalestineRefugeeLandingPage lang={'ar'}/>},
        {path: '/palestine-refugee-settlement-fr', element: <PalestineRefugeeLandingPage lang={'fr'}/>},
        {path: '/save-palestine-orphans-ar', element: <SaveOrphansLandingPage lang={'ar'}/>, exact: true},
        {path: '/save-palestine-orphans-fr', element: <SaveOrphansLandingPage lang={'fr'}/>, exact: true},
        {path: '/palestine-fr', element: <PalestineLandingPage _lang={'fr'}/>, exact: true},
        {path: '/palestine-ar', element: <PalestineArabicLandingPage/>, exact: true},
        {path: '/dashboard', element: <DashboardPage/>},
        {path: '/donor', element: <DonorPage/>},
        {path: '/dashboard/*', element: <DashboardPage/>},
        {path: '/donor/*', element: <DonorPage/>},
        {path: '/undefined/*', element: <HomePageUAT/>},
        {path: '/usa', element: <HomePageUSA/>},
        {path: '/appeals/:id', element: <DetailsProduct/>},
        {path: '/appeals/palestine/:id', element: <DetailsPalestineProduct/>},
        {path: '/:name', element: <AppealLandingPage/>},
        {path: '/:name/:product', element: <Products/>},
        {path: '/ramadan/fidya-kaffarah-uat', element: <FidyaKaffarahUat/>},
        {path: '/ramadan/last-10-nights', element: <LaylaTulQadrLandingPage/>},
        {path: '/laylatul-qadr-2024', element: <LaylatulQadr2024LandingPage/>},
        {path: '/volunteer-uat', element: <VolunteerUATPage/>},
        {path: '/sadaqah-jariyah-uat', element: <SadaqahjariahUat/>},
        {path: '/sadaqah-jariyah/build-a-water-well-uat', element: <BuildWaterWellUat/>},
        {path: '/sadaqah-jariyah/gift-of-sound', element: <GiftOfSoundLandingPage/>},
        {path: '/campaign-tt', element: <CampaignTiktok/>},
        {path: '/phishing-scams', element: <PhishingLandingPage/>},
        {path: '/admin-fee', element: <AdminFeesPage/>},
        {path: '/live-meals', element: <LiveMeals/>},
        {path: '/eid-al-fitr-2024', element: <EidAlFitr2024/>},
        {path: '/eid-mubarak-2024', element: <EidMubarak2024/>},
        {path: '/top-appeals', element: <TopAppeals/>},
        {path: '/qurbani-2024', element: <Qurbani2024LandingPage/>},
        {path: '/qurbani-2023', element: <Navigate to="/qurbani-2024" replace/>},
        {path: '/qurbani-palestine', element: <Qurbani2024LandingPage country={'Palestine'}/>},
        {path: '/qurban', element: <QurbanMainLandingPage lang={'en'} title={'Qurban'}/>},
        {path: '/qurban-ar', element: <QurbanMainLandingPage lang={'ar'}/>},
        {path: '/qurban-fr', element: <QurbanMainLandingPage lang={'fr'}/>},
        {path: '/cheapest-qurbani-2024', element: <CheapestQurbani2024LandingPage/>},
        {path: '/qurbani', element: <QurbanMainLandingPage lang={'en'} title={'Qurbani'}/>},
        {path: '/qurban/palestine', element: <QurbanPalestineLandingPage title="Qurban" lang={'en'}/>},
        {path: '/qurbani/palestine', element: <QurbanPalestineLandingPage title="Qurbani"/>},
        {path: '/qurban/palestine-fr', element: <QurbanPalestineLandingPage title="Qurban" lang={'fr'}/>},
        {path: '/qurban/palestine-ar', element: <QurbanPalestineLandingPage title="Qurban" lang={'ar'}/>},
        {path: '/qurbani-donation', element: <QurbaniDonationLandingPage/>},
        {path: '/qurbani-yemen', element: <Qurbani2024LandingPage country={'Yemen'}/>},
        {path: '/qurbani-egypt', element: <Qurbani2024LandingPage country={'Egypt'}/>},
        {path: '/qurbani-africa', element: <Qurbani2024LandingPage country={'Africa'}/>},
        {path: '/qurbani-syria', element: <Navigate to="/qurbani" replace/>},
        {path: '/qurbani-afghanistan', element: <Qurbani2024LandingPage country={'Afghanistan'}/>},
        {path: '/qurbani-jordan', element: <Qurbani2024LandingPage country={'Jordan'}/>},
        {path: '/qurbani-lebanon', element: <Qurbani2024LandingPage country={'Lebanon'}/>},
        {path: '/qurbani-bangladesh', element: <Qurbani2024LandingPage country={'Bangladesh'}/>},
        {path: '/eid-qurban-2024', element: <Qurbani2024LandingPage title={'Qurban'}/>},
        {path: '/qurban/dhul-hijjah', element: <QurbanDhulHijjahLandingPage/>},
        {path: '/qurban/ultimate-qurbani', element: <UltimateQurbanLandingPage title="Qurbani"/>},
        {path: '/qurban/ultimate-qurban', element: <UltimateQurbanLandingPage title="Qurban" lang={'en'}/>},
        {path: '/qurban/ultimate-qurban-ar', element: <UltimateQurbanLandingPage title="Qurban" lang={'ar'}/>},
        {path: '/qurban/ultimate-qurban-fr', element: <UltimateQurbanLandingPage title="Qurban" lang={'fr'}/>},
        {path: '/qurban/day-of-arafat', element: <QurbanDayOfArafatLandingPage title="Qurbani"/>},
        {path: '/qurban-campaign-1', element: <QurbanMainLandingPage isCampaign title={'Qurban'}/>},
        {path: '/qurbani-campaign-1', element: <QurbaniLandingPage isCampaign/>},
        {path: '/kurban-bayrami-2024', element: <KurbanBayramiLandingPage/>},
        {path: '/eid-al-adha-2024', element: <EidAlAdha2024LandingPage/>},
        {path: '/dhul-hijjah-2024', element: <DhulHijjah2024LandingPage/>},
        {path: '/pakistan-qurbani', element: <Qurbani2024LandingPage country={'Pakistan'}/>},
        {path: '/qurban/eid-al-adha', element: <QurbanEidAlAdhaLandingPage title="Qurban"/>},
        {path: '/crisis-and-emergencies/afghanistan', element: <AfghanistanLandingPageUAT/>},
        {path: '/widget', element: <WidgetEmbedded/>},
        {path: '/palestine-aia-partnership', element: <PalestineAiaPartnershipLandingPage/>},
        {path: '/orphans-aia-sponsorship', element: <OrphansAiaSponsorshipLandingPage/>},
        {path: '/doublethedonation', element: <DoubleTheDonationPage/>},
        {path: '/top-10', element: <Top10Products page="general"/>},
        {path: '/top-10-palestine', element: <Top10Products page="palestine"/>},
        {path: '/appeal/:id', element: <AppealLandingPage/>},
        {path: '/appeals/palestine-emergency', element: <MATWAppealDetailsLandingPageUATx campaign="palestine"/>},
        {path: '/appeals/gaza-camp', element: <MATWAppealDetailsLandingPageUATx campaign="gaza-camp"/>},
        {path: '/appeals/palestine-refugee-resettlement', element: <MATWAppealDetailsLandingPage campaign="egyptian"/>},
        {path: '/appeals/limbs-of-hope', element: <MATWAppealDetailsLandingPageUATx campaign="limbs-of-hope"/>},
        {path: '/appeals/palestine-water-pipeline', element: <MATWAppealDetailsLandingPageUATx campaign="palestine-water-pipeline"/>},
        {path: '/appeals/gaza-bakery', element: <MATWAppealDetailsLandingPageUATx campaign="gaza-bakery"/>},
        {
            path: '/appeals/north-lebanon-orphan',
            element: <MATWAppealDetailsLandingPage campaign="north-lebanon-orphan"/>
        },
        {path: '/appeals/appeal/limbs-of-hope', element: <MATWAppealLandingPage campaign="limbs-of-hope"/>},
        {path: '/appeals/appeal/palestine-emergency', element: <MATWAppealLandingPage campaign="palestine"/>},
        {path: '/appeals/appeal/palestine-refugee-resettlement', element: <MATWAppealLandingPage campaign="egyptian"/>},
        {path: '/appeals/appeal/palestine-water-pipeline', element: <MATWAppealLandingPage campaign="palestine-water-pipeline"/>},
        {path: '/children-of-gaza', element: <ChildrenOfGaza/>},
        {path: '/limbs-of-hope', element: <LimbsOfHopeLandingPage/>},
        {path: '/lebanon-appeal', element: <LebanonAppealLandingPage/>},
        {path: '/crisis-and-emergencies/palestine-impact', element: <PalestineImpact/>},
        {
            path: '/crisis-and-emergencies/children-in-gaza',
            element: <ChildrenInGazaLandingPage lang={'en'}/>,
            exact: true
        },
        {path: '/special-cases', element: <SpecialCasesLandingPage/>, exact: true},
        {path: '/gaza-appeal', element: <GazaAppealLandingPage/>, exact: true},
        {path: '/matw-appeals', element: <MatwAppeals/>, exact: true},
        {path: '/namaz-times/*', element: <NamazTimes/>, exact: true},
        {path: '/salah-times/*', element: <NamazTimes/>, exact: true},
        // {path: '/30-steps-to-jannah', element: <RamadanDailyDonation/>, exact: true},
        {path: '/30-steps-to-jannah', element: <LastTenNightsDailyGiving/>, exact: true},
        // {path: '/last-10-nights-giving', element: <LastTenNightsDailyGiving/>, exact: true},
        {path: '/tiktok', element: <TikTokLandingPage isTikotok={true}/>, exact: true},
        {path: '/snapchat', element: <TikTokLandingPage/>, exact: true},

        {path: '/namaz-timetable/*', element: <NamazTimes/>, exact: true},
        {path: '/salah-timetable/*', element: <NamazTimes/>, exact: true},
        {path: '/iftar-time-today/*', element: <SehrIftarTime />, exact: true},
        {path: '/iftar-time/*', element: <SehrIftarTime />, exact: true},
        {path: '/sehri-time-today/*', element: <SehrIftarTime/>, exact: true},
        {path: '/sehri-time/*', element: <SehrIftarTime/>, exact: true},
        {path: '/qibla-finder', element: <QiblaFinder />, exact: true},
        {path: '/home', element: <HomePageNew />, exact: true},
        {path: '/laylatul-qadr-last-10-nights', element: <LastTenNightsDailyGivingNew/>, exact: true},
        {path: '/test-widgets', element: <TestWidgetPage/>, exact: true},
    ];

    // If still loading (either from local storage or fetch), show nothing.
    // That way we never “flash” the original route before switching.
    if (loadingPaths) {
        return null;
        // or you can return <div>Loading...</div> or a spinner if you prefer
    }

    return (
        <RouteContext.Provider value={{routeState, setRouteState}}>
            {/* {showModal && !isAlreadyShown && <UkModal closeModal={closeModal}/>} */}
            <CampaignTracker/>
            <Routes>
                {prefixes.map((prefix) => (
                    <React.Fragment key={prefix}>
                        {routes.map((route, index) => {
                            if (cmsRoutes.some(cmsRoute => cmsRoute.route === route.path.toLowerCase() && cmsRoute.route_type === "builder")) {
                                return (
                                    <Route
                                        key={`${prefix}-${index}`}
                                        path={prefix + route.path}
                                        element={<AppealLandingPage key={location.pathname}/>}
                                        exact={route.exact}
                                    />
                                );
                            }
                            return (
                                <Route
                                    key={`${prefix}-${index}`}
                                    path={prefix + route.path}
                                    element={route.element}
                                    exact={route.exact}
                                />
                            );
                        })}
                    </React.Fragment>
                ))}
            </Routes>
        </RouteContext.Provider>
    );
}
